import { useRouter } from 'next/router';
import { useEffect } from 'react';

import Layout from '@/components/Layout';
import Landing from '@/components/Pages/Landing';
import withProfileCreated from '@/hoc/withProfileCreated';
import { useAuth } from '@/hooks/useAuth';

import type { NextPage } from 'next';

const LoginPage: NextPage = () => {
  const router = useRouter();
  const callbackUri = (router.query.callbackUri as string) ?? `/`;
  const replace = router.replace;

  const { authModel } = useAuth();

  useEffect(() => {
    if (authModel.state === `profile-created`) {
      replace(callbackUri);
    }
  }, [authModel, callbackUri, replace]);

  return (
    <Layout headerType={`static`}>
      <Landing cards={[]}></Landing>
    </Layout>
  );
};

export default withProfileCreated(LoginPage);
