import styled from 'styled-components';

export const SpinnerWrapper = styled.div`
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
  text-align: center;

  -webkit-animation: sk-rotate 2s infinite linear;
  animation: sk-rotate 2s infinite linear;
`;
export const SpinnerDot1 = styled.div`
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #fff;
  border-radius: 100%;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
`;
export const SpinnerDot2 = styled.div`
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  background-color: #fff;
  border-radius: 100%;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;

  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
`;

export type SpinnerProps = {};

const Spinner = (props: SpinnerProps) => {
  const {} = props;

  return (
    <SpinnerWrapper>
      <SpinnerDot1></SpinnerDot1>
      <SpinnerDot2></SpinnerDot2>
    </SpinnerWrapper>
  );
};

export default Spinner;
