import { ReactNode } from 'react';

export type BackdropProps = {
  children?: ReactNode;
  zIndex?: number;
};

const Backdrop = (props: BackdropProps) => {
  const { children, zIndex } = props;

  return (
    <div
      className={`bg-transparent fixed top-[50%] left-0 right-0 mx-auto flex h-screen w-screen translate-y-[-50%] items-center justify-center`}
      style={{
        zIndex: zIndex,
      }}
    >
      {children}
    </div>
  );
};

export default Backdrop;
