import { withAuthenticationRequired } from '@auth0/auth0-react';
import React, { ComponentType } from 'react';

import Backdrop from '@/components/Module/Backdrop';
import Spinner from '@/components/Module/Spinner';
import InternalServerError from '@/components/Pages/InternalServerError';
import { useAuth } from '@/hooks/useAuth';

function withAuthenticated<T extends object>(
  WrappedComponent: ComponentType<T>,
) {
  // Try to create a nice displayName for React Dev Tools.
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || `Component`;

  // Creating the inner component. The calculated Props type here is the where the magic happens.
  const ComponentWithAuthenticated = (props: T) => {
    // Fetch the props you want to inject. This could be done with context instead.
    const { authModel } = useAuth();

    if (authModel.state === `loading`) {
      return (
        <Backdrop>
          <Spinner />
        </Backdrop>
      );
    }

    if (authModel.state === `error`) {
      return <InternalServerError />;
    }

    // props comes afterwards so the can override the default ones.
    return <WrappedComponent {...(props as T)} />;
  };

  ComponentWithAuthenticated.displayName = `withAuthenticated(${displayName})`;

  return withAuthenticationRequired(ComponentWithAuthenticated, {
    onRedirecting: () => (
      <Backdrop>
        <Spinner />
      </Backdrop>
    ),
    loginOptions: {
      redirectMethod: `replace`,
    },
  });
}

export default withAuthenticated;
