import { useRouter } from 'next/router';
import { stringifyUrl } from 'query-string';
import React, { ComponentType, useEffect } from 'react';

import Backdrop from '@/components/Module/Backdrop';
import Spinner from '@/components/Module/Spinner';
import InternalServerError from '@/components/Pages/InternalServerError';
import withVerified from '@/hoc/withVerified';
import { useAuth } from '@/hooks/useAuth';

function withProfileCreated<T extends object>(
  WrappedComponent: ComponentType<T>,
) {
  // Try to create a nice displayName for React Dev Tools.
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || `Component`;

  // Creating the inner component. The calculated Props type here is the where the magic happens.
  const ComponentWithProfileCreated = (props: T) => {
    // Fetch the props you want to inject. This could be done with context instead.
    const { authModel } = useAuth();
    const router = useRouter();
    const replace = router.replace;

    useEffect(() => {
      if (authModel.state === `ready-to-signup`) {
        replace(
          stringifyUrl({
            url: `/signup`,
            query: {
              callbackUri: global?.window?.location?.href,
            },
          }),
        );
      }
    }, [authModel.state, replace]);

    if (authModel.state === `loading`) {
      return (
        <Backdrop>
          <Spinner />
        </Backdrop>
      );
    }

    if (authModel.state === `error`) {
      return <InternalServerError />;
    }

    // props comes afterwards so the can override the default ones.
    return <WrappedComponent {...(props as T)} />;
  };

  ComponentWithProfileCreated.displayName = `withProfileCreated(${displayName})`;

  return withVerified(ComponentWithProfileCreated);
}

export default withProfileCreated;
