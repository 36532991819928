import { useRouter } from 'next/router';
import { stringifyUrl } from 'query-string';
import React, { ComponentType, useEffect } from 'react';

import Backdrop from '@/components/Module/Backdrop';
import Spinner from '@/components/Module/Spinner';
import InternalServerError from '@/components/Pages/InternalServerError';
import withAuthenticated from '@/hoc/withAuthenticated';
import { useAuth } from '@/hooks/useAuth';

function withVerified<T extends object>(WrappedComponent: ComponentType<T>) {
  // Try to create a nice displayName for React Dev Tools.
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || `Component`;

  // Creating the inner component. The calculated Props type here is the where the magic happens.
  const ComponentWithVerified = (props: T) => {
    // Fetch the props you want to inject. This could be done with context instead.
    const { authModel } = useAuth();
    const router = useRouter();
    const replace = router.replace;

    useEffect(() => {
      if (authModel.state === `email-pending`) {
        replace(
          stringifyUrl({
            url: `/verify-email`,
            query: {
              callbackUri: global?.window?.location?.href,
            },
          }),
        );
      }
    }, [authModel.state, replace]);

    if (authModel.state === `loading`) {
      return (
        <Backdrop>
          <Spinner />
        </Backdrop>
      );
    }

    if (authModel.state === `error`) {
      return <InternalServerError />;
    }

    // props comes afterwards so the can override the default ones.
    return <WrappedComponent {...(props as T)} />;
  };

  ComponentWithVerified.displayName = `withVerified(${displayName})`;

  return withAuthenticated(ComponentWithVerified);
}

export default withVerified;
